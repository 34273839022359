.page {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.page main {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.page main::before {
  content: ' ';
  display: block;
  position: fixed;
  inset: 0;
  opacity: 0.2;
  z-index: 0;
  background-image: url('/img/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.home-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: var(--margin-04);
}

.events-list {
  width: 100%;
  max-width: 700px;
}

.events-list > *:not(:last-child) {
  margin-bottom: var(--margin-02);
}
