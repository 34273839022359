.header {
  width: 100%;
  z-index: 100;
  background: var(--bg);
  padding: 0;
  border-bottom: 2px solid var(--fg);
}

.wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.logo-wrapper {
  flex: 0 0 auto;
  width: 80px;
  height: auto;
  display: block;
}

.logo {
  width: 100%;
  height: auto;
  display: block;
}

.logo.-dark {
  display: none;
}

.nav-routes {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  font-weight: bold;
  gap: var(--margin-02);
}

.nav-routes > *:not(:last-child) {
  /* margin-right: var(--margin-02); */
}

.nav-routes a {
  text-transform: uppercase;
  text-decoration: none;
}

@media screen and (width <= 900px) {
  .wrapper {
    flex-flow: column;
  }

  .nav-routes {
    gap: var(--margin-01);
    margin-bottom: var(--margin-02);
  }
}

@media (prefers-color-scheme: dark) {
  .logo.-dark {
    display: block;
  }

  .logo.-light {
    display: none;
  }
}
