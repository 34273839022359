.button {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  gap: var(--margin-01);
  width: max-content;
  max-width: 50ch;
  border: 2px solid var(--hl-02);
  border-radius: var(--margin-01);
  padding: var(--margin-q) var(--margin-01);
  text-decoration: none;
  background: var(--fg);
  color: var(--bg);
  transition: all 0.2s ease;
}

.button:hover {
  cursor: pointer;
  color: var(--bg);
  border-color: var(--hl-01);
}

.button:disabled {
  border-color: transparent;
  background-color: var(--fg-alt);
}
