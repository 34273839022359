.event {
  --border-color: var(--fg);
  --border-width: 3px;

  background: var(--bg);
  border-radius: var(--margin-01);
  overflow: hidden;
  border: var(--border-width) solid var(--border-color);
  display: grid;
  grid-template-columns: 40% minmax(0, 1fr);
  width: 100%;
}

.event-content {
  width: 100%;
}

.event-header {
  width: 100%;
  border-bottom: var(--border-width) solid var(--border-color);
  padding: var(--margin-01) var(--margin-02);
}

.event-title {
  font-family: var(--font-sans);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: var(--margin-q);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.event-title,
.event-subtitle {
  font-family: var(--font-sans);
}

.event-subheader {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--margin-01);
}

.event-media {
  position: relative;
  height: 100%;
  aspect-radio: 1/1;
  border-right: var(--border-width) solid var(--hl-02);
}

.event-badge {
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.2em;
  font-weight: bold;
  background: var(--fg);
  color: var(--bg);
  padding: var(--margin-h) var(--margin-02);
  border-radius: 0 0 var(--margin-01) 0;
}

.event-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-info {
  padding: var(--margin-01) var(--margin-02);
}

.event-dates {
  display: inherit;
  display: flex;
  flex-flow: row;
  white-space: nowrap;
}

@media screen and (width <= 700px) {
  .event {
    grid-template-columns: 1fr;
  }

  .event-media {
    border-right: 0;
    border-bottom: var(--border-width) solid var(--hl-02);
  }
}

@media (prefers-color-scheme: dark) {
  .event {
    --border-color: var(--bg-alt);
  }

  .event-badge {
    background: var(--bg-alt);
    color: var(--fg);
  }
}
